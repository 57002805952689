.spoil-alert > img { max-width: 70%; }
.card-article .card-title { line-height: 1.3; }

.post-media-size-tall { max-width: 400px; }
.post-media { margin: 0 auto .75rem; }
.post-media .post-media-image img {
    display: block; margin: 0 auto;
    max-width: 100%; width: 100%;
    border-radius: .375rem;
}
.post-media .post-media-caption {
    position: relative; text-align: center;
    font-size: .8125rem; padding: .375rem;
    color: #95aac9;
}

.social-bar {
    position: fixed;
    left: 10px;
    top: 180px;
}

.social-bar .btn-social-share {
    display: block;
    margin: 5px 0;
    color: #fff;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;;
    -webkit-border-radius: 50% !important;;
    width: 36px !important;
    height: 36px;
    text-align: center;
}

.social-bar .btn-social-share:hover {
    color: #fff;
    opacity: 0.8;
}

.social-bar .btn-social-share i {
    line-height: 36px;
    font-size: 20px;
}

.social-bar .btn-social-share-facebook {
    background-color: #3b5999;
}

.social-bar .btn-social-share-messenger {
    background-color: rgb(0, 132, 255);
}

.social-bar .btn-social-share-comment {
    background-color: rgb(132, 132, 132);
    position: relative;
}

.social-bar .btn-social-share > span {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #d71002;
    font-size: 11px;
    color: #fff;
    padding: 2px 5px;
    line-height: 12px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    font-weight: bold;
}

/**** ATM ADS STYLING OVERRIDE ****/
.article-ads { margin-left: -1rem; margin-right: -1rem; padding-top: 3px; background-color: #edf2f9; }
#amg-in-article-wrapper { border: 0 !important; margin-left: -1rem !important; margin-right: -1rem !important; background-color: #edf2f9; }
/**** END ****/

#article .comments { border: 1px solid #edf2f9; border-radius: .375rem; }

@media (max-width: 767px) {
    .card-article .card-body { padding: 1rem; }
    .card-article .card-header { border-radius: 0; min-height: auto; padding: 1rem; }
}

@media (min-width: 576px) {
    .page-load-status { margin-top: 0 !important; }
}